import React from 'react';

function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <img src={`${process.env.PUBLIC_URL}/sonoptima_logo.png`} alt="logo" style={{ width: '50%', maxWidth: '300px', marginTop: '-100px', paddingTop: '0px' }} />
      <p style={{ width: '70%', maxWidth: '650px', textAlign: 'center' }}>
      Sonoptima is a medical device startup focusing on wearable ultrasound devices. Our goal is to change the way ultrasound technology is used in various healthcare settings, making it more efficient for patients and healthcare providers. 
      </p>
      <a target="_top" href="mailto:info@sonoptima.com" style={{ marginTop: '20px', backgroundColor:'#007BFF', color: 'white', padding:'10px 20px', textDecoration: 'none', borderRadius:'5px'}}>
        Email us to get in touch
      </a>
    </div>
  );
}

export default App;
